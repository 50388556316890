






























































































import { Component, Vue, Prop } from "vue-property-decorator";

// The MDC Component
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCButtonOutlined from "@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue";

// View Component
import TimeSlotsPanel from "@/views/shipment/ConfirmAndProceedForm/TimeSlotsPanel.vue";

@Component({
  components: {
    TheMDCButtonShaped,
    TheMDCButtonShapedRaised,
    TheMDCButtonOutlined,
    TimeSlotsPanel
  }
})
export default class ConfirmAndProceedForm extends Vue {
  get text() {
    return {
      title: this.$t(
        "shipment.confirmAndProceedForm.confirmAndProceedForm.title"
      ),
      return: this.$t(
        "shipment.confirmAndProceedForm.confirmAndProceedForm.return"
      ),
      proceed: this.$t(
        "shipment.confirmAndProceedForm.confirmAndProceedForm.proceed"
      )
    };
  }

  mounted() {
    this.$store.commit("showTitle");
    this.$store.commit("hideNavBar");
    this.$store.commit("showTopBar");

    this.$emit("proceed"); // event to change progress indicator in TheTitle.vue
    this.$nextTick(function () {;
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  get selectedCourierId() {
    return this.$store.getters["shipment/getSelectedRate"]?.courier
      .externalCourierId;
  }
  get rates() {
    return this.$store.getters["shipment/getRates"];
  }
  get canProceed() {
    const hasSelectedRate = this.$store.getters["shipment/hasSelectedRate"]; // if user has select rate preference
    const isDirectHandOver = // if user selected direct handover option
      this.$store.getters["shipment/getSelectedPickupOption"] ===
      "markAsDirectlyHandover";
    const hasSelectedPickupTimeSlot = this.$store.getters[ // if user has selected pickup time slots
      "shipment/hasSelectedPickupTimeSlot"
    ];
    return hasSelectedRate && (isDirectHandOver || hasSelectedPickupTimeSlot);
  }

  private reloadAndRetrievePickupSlots(selectedRate: ShipmentRate) {
    if (this.selectedCourierId === selectedRate.courier.externalCourierId) {
      // if already selected a rate, unselect it
      this.clearPickupAndSelectedRate();
    } else {
      // if not already selected a rate , select a rate
      this.clearPickupAndSelectedRate();
      this.$store.commit("shipment/selectRate", selectedRate);
    }
  }

  private clearPickupAndSelectedRate() {
    this.$store.commit("shipment/selectRate", null); // delete already selected rate
    this.$store.commit("shipment/resetPickup"); // delete already selected pickup info
  }
  async proceed() {
    await this.$router.push({ name: "ConfirmAndPay" });
  }
  toLastPage() {
    this.clearPickupAndSelectedRate();
    this.$store.commit("shipment/resetRate"); // delete fetched data
    this.$router.back();
  }
}
