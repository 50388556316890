var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-slots-panel__container" }, [
    _c("div", { staticClass: "inner-panel__container grid-container" }, [
      _c(
        "div",
        { staticClass: "time-slot-panel__row inner-grid-row-container" },
        [
          _c("div", { staticClass: "left-item" }, [
            _vm._v(_vm._s(_vm.text.name))
          ]),
          _c("div", { staticClass: "right-item" }, [
            _vm._v(_vm._s(_vm.courier.name))
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "time-slot-panel__row inner-grid-row-container" },
        [
          _c("div", { staticClass: "left-item" }, [
            _vm._v(_vm._s(_vm.text.totalCharge))
          ]),
          _c("div", { staticClass: "right-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.text.dollarSign) +
                " " +
                _vm._s(_vm.rate.totalCharge) +
                "\n      "
            )
          ])
        ]
      ),
      _vm.rate.estimatedImportTax !== 0
        ? _c(
            "div",
            { staticClass: "time-slot-panel__row inner-grid-row-container" },
            [
              _c("div", { staticClass: "left-item" }, [
                _vm._v(_vm._s(_vm.text.estimateImportTax))
              ]),
              _c("div", { staticClass: "right-item" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.dollarSign) +
                    " " +
                    _vm._s(_vm.rate.estimatedImportTax) +
                    "\n      "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.rate.importTaxCharge !== 0
        ? _c(
            "div",
            { staticClass: "time-slot-panel__row inner-grid-row-container" },
            [
              _c("div", { staticClass: "left-item" }, [
                _vm._v(_vm._s(_vm.text.importTaxCharge))
              ]),
              _c("div", { staticClass: "right-item" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.dollarSign) +
                    " " +
                    _vm._s(_vm.rate.importTaxCharge) +
                    "\n      "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.rate.importDutyCharge !== 0
        ? _c(
            "div",
            { staticClass: "time-slot-panel__row inner-grid-row-container" },
            [
              _c("div", { staticClass: "left-item" }, [
                _vm._v(_vm._s(_vm.text.importDutyCharge))
              ]),
              _c("div", { staticClass: "right-item" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.dollarSign) +
                    " " +
                    _vm._s(_vm.rate.importDutyCharge) +
                    "\n      "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.rate.estimatedImportDuty !== 0
        ? _c(
            "div",
            { staticClass: "time-slot-panel__row inner-grid-row-container" },
            [
              _c("div", { staticClass: "left-item" }, [
                _vm._v(_vm._s(_vm.text.estimatedImportDuty))
              ]),
              _c("div", { staticClass: "right-item" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.dollarSign) +
                    " " +
                    _vm._s(_vm.rate.estimatedImportDuty) +
                    "\n      "
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "time-slot-panel__row inner-grid-row-container" },
        [
          _c("div", { staticClass: "left-item" }, [
            _vm._v(_vm._s(_vm.text.estimateDeliveryDays))
          ]),
          _c("div", { staticClass: "right-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.rate.maxDeliveryDay || _vm.rate.minDeliveryDay) +
                "\n        " +
                _vm._s(_vm.text.days) +
                "\n      "
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "time-slot-panel__row inner-grid-row-container" },
        [
          _c("div", { staticClass: "left-item" }, [
            _vm._v(_vm._s(_vm.text.description))
          ]),
          _c("div", { staticClass: "right-item" }, [
            _vm._v(_vm._s(_vm.rate.fullDescription))
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "time-slot-panel__row inner-grid-row-container" },
        [
          _c("div", { staticClass: "left-item" }, [
            _vm._v(_vm._s(_vm.text.select))
          ]),
          _c("div", { staticClass: "right-item inner-grid-pickup-container" }, [
            _vm.showOption
              ? _c(
                  "div",
                  { staticClass: "time-slot-panel__field" },
                  [
                    _c("TheMDCSelectOutlined", {
                      ref: "pickupOptionMenu",
                      attrs: {
                        reference: "pickupOptionMenu",
                        label: _vm.text.pickupOptionMenu,
                        menuList: _vm.pickupOptionMenu,
                        isRequired: true,
                        event: "select-pickup-option"
                      },
                      on: { "select-pickup-option": _vm.selectPickupOption }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.courier.externalCourierId == _vm.courierId && _vm.showDay
              ? _c(
                  "div",
                  { staticClass: "time-slot-panel__field" },
                  [
                    _c("TheMDCSelectOutlined", {
                      ref: "dayMenu",
                      attrs: {
                        reference: "dayMenu",
                        label: _vm.text.dayMenu,
                        menuList: _vm.dayMenu,
                        isRequired: true,
                        event: "select-day"
                      },
                      on: { "select-day": _vm.selectDay }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.courier.externalCourierId == _vm.courierId && _vm.showTime
              ? _c(
                  "div",
                  { staticClass: "time-slot-panel__field" },
                  [
                    _c("TheMDCSelectOutlined", {
                      ref: "timeMenu",
                      attrs: {
                        reference: "timeMenu",
                        label: _vm.text.timeMenu,
                        menuList: _vm.timeMenu,
                        isRequired: true,
                        event: "select-time"
                      },
                      on: { "select-time": _vm.selectTime }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }