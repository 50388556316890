
















































































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";

@Component({
  components: {
    TheMDCSelectOutlined
  }
})
export default class TimeSlotPanel extends Vue {
  @Prop() courierId!: string;

  get text() {
    return {
      //label tags
      pickupOptionMenu: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.pickupOptionMenu"
      ),
      dayMenu: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.dayMenu"),
      timeMenu: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.timeMenu"
      ),

      // left column
      name: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.name"),
      totalCharge: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.totalCharge"
      ),
      estimatedImportTax: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.estimatedImportTax"
      ),
      estimatedImportDuty: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.estimatedImportDuty"
      ),
      importTaxCharge: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.importTaxCharge"
      ),
      importDutyCharge: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.importDutyCharge"
      ),

      estimateDeliveryDays: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.estimateDeliveryDays"
      ),
      description: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.description"
      ),
      select: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.select"),

      // in text
      to: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.to"),
      days: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.days"),
      dollarSign: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.dollarSign"
      ),
      directlyHandover: this.$t(
        "shipment.confirmAndProceedForm.timeSlotsPanel.directlyHandover"
      ),
      pickup: this.$t("shipment.confirmAndProceedForm.timeSlotsPanel.pickup")
    };
  }

  get showOption() {
    return (
      this.$store.getters["shipment/hasSelectedRate"] &&
      this.$store.getters["shipment/getSelectedRate"].courier
        .externalCourierId === this.courierId
    );
  }
  get showDay() {
    return this.$store.getters["shipment/getSelectedPickupOption"] === "pickup";
  }
  get showTime() {
    return this.$store.getters["shipment/hasSelectedPickupDate"];
  }

  get courier() {
    return this.rate.courier;
  }
  get rate(): ShipmentRate {
    return this.$store.getters["shipment/getRates"].filter(
      (rate: ShipmentRate) => {
        return rate.courier.externalCourierId == this.courierId;
      }
    )[0];
  }

  get pickupOptionMenu(): MenuItem[] {
    // build pickup option Menu
    const pickupOptionMenu: MenuItem[] = [
      {
        key: "markAsDirectlyHandover",
        value: "markAsDirectlyHandover",
        expression: this.text.directlyHandover
      }
    ];
    if (this.rate.courierDoesPickup) {
      pickupOptionMenu.push({
        key: "pickup",
        value: "pickup",
        expression: this.text.pickup
      });
    }
    return pickupOptionMenu;
  }

  get dayMenu(): MenuItem[] {
    // build day menu
    const dayMenu: MenuItem[] = [];
    this.allTimeSlots.forEach((timeSlots: TimeSlots) => {
      const thisDay = Object.keys(timeSlots)[0];
      dayMenu.push({
        key: thisDay,
        value: thisDay,
        expression: thisDay
      });
    });
    return dayMenu;
  }

  get timeMenu(): MenuItem[] {
    // build time menu
    const timeMenu: MenuItem[] = [];
    if (this.$store.getters["shipment/hasSelectedPickupDate"]) {
      // get targeted date
      const selectedPickupDate = this.$store.getters[
        "shipment/getSelectedPickupDate"
      ];
      const thisTimeSlot: TimeSlots = this.allTimeSlots.filter(
        (timeSlots: TimeSlots) => {
          return Object.keys(timeSlots)[0] === selectedPickupDate;
        }
      )[0];

      // build menuItem[]
      thisTimeSlot[selectedPickupDate].forEach((timeSlot: TimeSlot) => {
        timeMenu.push({
          key: timeSlot.minTime + timeSlot.maxTime,
          value: timeSlot.minTime + "T" + timeSlot.maxTime,
          expression: timeSlot.minTime + "-" + timeSlot.maxTime
        });
      });
    }

    return timeMenu;
  }

  get allTimeSlots(): TimeSlots[] {
    const allTimeSlots: TimeSlots[] =
      this.$store.getters["shipment/getTimeSlots"] || [];
    return allTimeSlots;
  }

  private selectPickupOption(
    pickupOption: "pickup" | "markAsDirectlyHandover"
  ) {
    if (pickupOption == "markAsDirectlyHandover") {
      // clear all if directly handed over
      this.$store.commit("shipment/resetPickup");
    }
    this.$store.commit("shipment/selectPickupOption", pickupOption);
  }
  private selectDay(day: string | undefined) {
    this.$store.commit("shipment/selectPickupDate", day);
  }
  private selectTime(time: string | undefined) {
    let timeSlot;
    if (time) {
      timeSlot = {
        minTime: time.split("T")[0],
        maxTime: time.split("T")[1]
      };
    } else {
      timeSlot = time;
    }
    this.$store.commit("shipment/selectPickupTime", timeSlot);
  }
}
